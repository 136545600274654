<template>
  <div class="default-tabela premio-tabela">
    <div class="tabela-titulo ">
      <h1>{{ camposInput.titulo }}</h1>
    </div>

    <div class="tabela-titulo uploud">
      <h2>UPLOAD DE PLANILHA</h2>
      <div class="alinha-uploud" v-if="!verificaPlanilhaEnviada">
        <div class="btn-uploud">
          <label for="selecao-arquivo">SELECIONAR PLANILHA</label>
          <input
            type="file"
            ref="file"
            v-on:change="handleFileUpload()"
            id="selecao-arquivo"
            name="avatar"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </div>
        <button class="btn btn-download" @click="montarFormData()">
          ENVIAR
        </button>
      </div>
      <div v-else>
        <p>Já existe uma planilha em processamento.</p>
      </div>
    </div>

    <div class="tabela-titulo ">
      <h2>LISTA DE PREMIAÇÕES</h2>
      <DownloadButton
        area="relatorio_de_premiacao"
        :getData="getData"
        :list="planilhaParaDownload"
        :title="'Extrair lista'"
      />
    </div>

    <div class="tabela-filtros default-formulario">
      <div class="formulario-item">
        <label class="formulario-item-label">Nome:</label>
        <input type="text" v-model="form.nome" placeholder="Nome" />
      </div>
      <div class="formulario-item">
        <label class="formulario-item-label">CPF:</label>
        <input
          type="text"
          v-model="form.cpf"
          v-mask="'999.999.999-99'"
          placeholder="000.000.000-00"
        />
      </div>
      <div class="formulario-item">
        <label class="formulario-item-label">E-mail:</label>
        <input
          type="text"
          v-model="form.email"
          placeholder="email@exemplo.com"
        />
      </div>
      <div class="formulario-item">
        <label class="formulario-item-label">Status:</label>
        <multiselect
          v-model="form.statusPremiacao"
          :options="statusCupom"
          label="descricao"
          track-by="id"
          :searchable="false"
          :allow-empty="false"
          placeholder="Selecione"
          select-label="Selecione"
          deselect-label="Selecionado"
          selected-label="Selecionado"
          @close="fecharSelect('statusPremiacao')"
        />
      </div>
      <div class="formulario-item">
        <div class="container-input container-datepicker">
          <label class="formulario-item-label">Data de Início:</label>
          <datepicker
            v-model="form.dataCadastroInicial"
            :language="ptBR"
          ></datepicker>
        </div>
      </div>
      <div class="formulario-item">
        <div class="container-input container-datepicker">
          <label class="formulario-item-label">Data final:</label>
          <datepicker
            v-model="form.dataCadastroFinal"
            :language="ptBR"
          ></datepicker>
        </div>
      </div>
      <div class="btns-formulario">
        <button class="btn btn-enviar" @click="filtrar">Filtrar</button>
        <button class="btn" @click="limparFiltros">Limpar</button>
      </div>
    </div>
    <!-- .tabela-filtro -->

    <transition name="fade-left" mode="out-in">
      <div v-if="semDados" class="tabela-sem-dados" key="Vazio">
        <p>Sem dados no momento</p>
      </div>
      <div v-else class="tabela-informacoes" key="Lista">
        <div class="tabela-linha tabela-linha-titulo">
          <div class="tabela-linha-cadastro"><p>Data de Envio</p></div>
          <!-- <div class="tabela-linha-numero"><p>Número do Cupom</p></div> -->
          <div class="tabela-linha-nome"><p>Nome</p></div>
          <div class="tabela-linha-nome"><p>CPF</p></div>
          <!-- <div class="tabela-linha-cpf"><p>Número da Sorte</p></div> -->
          <div class="tabela-linha-email"><p>E-mail</p></div>
          <div class="tabela-linha-cadastro"><p>Status</p></div>
          <div class="tabela-linha-cadastro"><p>Prêmio</p></div>
          <div class="tabela-linha-moderar"><p>Moderar</p></div>
        </div>
        <div
          class="tabela-linha tabela-linha-informacoes"
          v-for="itemTabela in dadosTabela"
          :key="itemTabela.id"
        >
          <div class="tabela-linha-cadastro">
            <p>{{ itemTabela.dataPremiacao | filterData }}</p>
          </div>
          <!-- <div class="tabela-linha-numero">
            <p>{{ itemTabela.numeroCupom }}</p>
          </div> -->
          <div class="tabela-linha-nome">
            <p>{{ itemTabela.nomeUsuario }}</p>
          </div>
          <div class="tabela-linha-nome">
            <p>{{ itemTabela.cpf | formataCPF }}</p>
          </div>
          <!-- <div class="tabela-linha-cpf">
            <p>{{ itemTabela.numeroSorte }}</p>
          </div> -->
          <div class="tabela-linha-email">
            <p>{{ itemTabela.email }}</p>
          </div>

          <div class="tabela-linha-cadastro">
            <p
              v-if="itemTabela.descricaoStatusPremiacao"
              :class="`cupom-status ${itemTabela.descricaoStatusPremiacao}`"
            >
              {{ itemTabela.descricaoStatusPremiacao }}
            </p>
            <p v-if="!itemTabela.descricaoStatusPremiacao">Não informado</p>
          </div>

          <div class="tabela-linha-cadastro">
            <p v-if="itemTabela.premio">
              R${{ formatarPremio(itemTabela.premio) }}
            </p>
            <p v-else>Não informado</p>
          </div>
          <div class="tabela-linha-moderar">
            <button
            class="btn btn-moderar"
            @click="moderarPremio(itemTabela.idUsuario, itemTabela.idHistoricoPremiacaoInstantanea, itemTabela.dataPremiacao)"
            :disabled="itemTabela.descricaoStatusPremiacao != 'Pendente'">Moderar</button>
          </div>
        </div>
      </div>
    </transition>
    <!-- .tabela-paginacao -->
    <div class="tabela-paginacao paginacao" v-if="!semDados">
      <div>
        <p>Total</p>
      </div>
      <div>
        {{ paginacao.totalPaginas }}
      </div>
      <div
        class="paginacao-btn"
        v-if="paginacao.numeroPagina >= 2"
        @click="nudaPagina(1)"
      >
        <p>1</p>
      </div>
      <div>
        <div class="button" v-if="pageNumber > 1" @click="prevPage()">
          <img
            src="~@/assets/images/icones/next.svg"
            alt="<"
            title="Anterior"
            style=" transform: rotate(180deg);"
          />
        </div>
      </div>
      <div
        v-if="paginacao.numeroPagina > 1"
        @click="nudaPagina(paginacao.numeroPagina - 1)"
      >
        <p>{{ paginacao.numeroPagina - 1 }}</p>
      </div>
      <div class="pagina-atual" v-if="paginacao.numeroPagina >= 1">
        {{ paginacao.numeroPagina }}
      </div>
      <div
        v-if="
          paginacao.totalPaginas >= 3 &&
            paginacao.numeroPagina < paginacao.totalPaginas
        "
        @click="nudaPagina(paginacao.numeroPagina + 1)"
      >
        <p>{{ paginacao.numeroPagina + 1 }}</p>
      </div>
      <div>
        <div
          class="button"
          v-if="dadosTabela.length === 10"
          @click="nextPage()"
        >
          <img src="~@/assets/images/icones/next.svg" alt=">" title="Próximo" />
        </div>
      </div>
      <div
        class="paginacao-btn"
        v-if="paginacao.numeroPagina < paginacao.totalPaginas"
        @click="nudaPagina(paginacao.totalPaginas)"
      >
        <p>{{ paginacao.totalPaginas }}</p>
      </div>
    </div>
    <!-- <Paginacao :paginacao="paginacao" pagina="premiacao"  v-if="!semDados"/> -->
    <!-- .tabela-paginacao -->
  </div>
</template>

<script>
import AwesomeMask from "awesome-mask";
import {
  listarPremiacaoInstantanea,
  historicoPremiacaoInstantanea,
  enviarPlanilhaPremiacao,
  gerarRelatorio,
  verificaPlanilha
} from "@/api/premiacao";
import { _imagemMobile, _imagemDesktop } from "@/api/_caminhosApi";
import { numberToReal } from "@/utils/money";
import { filterData, stringToDatetimeZone } from "@/utils/date";
import { formataCPF } from "@/utils/masks";
import DownloadButton from "@/components/download-button/DownloadButton.vue";
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
import Multiselect from "vue-multiselect";
import moment from "moment";
// import Paginacao from "@/components/paginacao/Paginacao.vue";

let filtrosBase = {
  nome: null,
  cpf: null,
  email: null,
  dataCadastroInicial: null,
  dataCadastroFinal: null,
  statusPremiacao: 0
};
export default {
  directives: {
    mask: AwesomeMask
  },
  data() {
    return {
      ptBR: ptBR,
      planilhaParaDownload: [],
      tituloDownload: "",
      file: "",
      pageNumber: 1,
      semDados: true,
      form: filtrosBase,
      filtros: {},
      arquivo: null,
      nomeArquivo: "Nenhum arquivo selecionado",
      statusCupom: [
        { descricao: "Todos", id: 0 },
        { descricao: "Aprovado", id: 1 },
        { descricao: "Reprovado", id: 2 },
        { descricao: "Pendente", id: 3 }
      ],
      calendario: {
        inicio: "",
        final: ""
      },
      calendarioTexto: {
        placeholder: "Selecione o período",
        fromText: "De",
        toText: "a",
        resetText: "Limpar",
        confirmText: "Confirmar",
        weekList: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        monthList: [
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro"
        ]
      }
    };
  },
  components: {
    DownloadButton,
    Datepicker,
    Multiselect
    // Paginacao
  },
  computed: {
    camposInput() {
      return this.$store.state.premiacao.componenteAtivado;
    },
    dadosTabela() {
      return this.$store.state.premiacao.tabela;
    },
    cuponsCompletos() {
      return this.$store.state.cupom.dadosCompletos;
    },
    verificaPlanilhaEnviada() {
      return this.$store.state.premiacao.verificaPlanilha;
    },
    paginacao() {
      return this.$store.state.premiacao.paginacao;
    }
  },
  methods: {
    _imagemMobile,
    _imagemDesktop,
    numberToReal,
    enviarPlanilhaPremiacao,
    gerarRelatorio,
    verificaPlanilha,
    montarDownload(data) {
      this.tituloDownload = ["Relatório de Premiação Instantânea"];
      if (data && Object.keys(data).length) {
        return (this.planilhaParaDownload = data.map(v => ({
          Nome: v.nome,
          CPF: this.formataCPF(v.cpf),
          email: v.email,
          "Data de Nascimento": moment(v.dataNascimento).format("DD/MM/YYYY"),
          DDD: this.getDD(v.celular),
          Celular: this.removeDD(v.celular),
          Telefone: this.removeDD(v.telefone),
          CEP: v.cep,
          Endereço: v.endereco,
          "Número do endereço": v.numero,
          Complemento: v.complemento,
          Bairro: v.bairro,
          Cidade: v.cidade,
          UF: v.uf,
          "UF da nota": v.uf,
          "Valor do voucher": v.premio,
          "Data da premiação instantânea": moment(v.dataPremioLiberado).format(
            "DD/MM/YYYY HH:mm"
          ),
          "Status premiação": v.statusPremiacao == 1 ? "Aprovado" : v.statusPremiacao == 2 ? "Reprovado" : "Pendente",
        })));
      }
    },
    getDD(number) {
      return number.substring(0, 2);
    },
    removeDD(number) {
      if (number) return (number = number.replace(/^.{2}/g, ""));
    },
    formatarPremio(value) {
      let resultado = value
        .split(/[A-Za-z]/)
        .join("")
        .split("-")
        .join("")
        .split(" ")
        .join(" ")
        .split("$")
        .join(" ")
        .split(",");
      return resultado[0];
    },
    handleFileUpload() {
      this.arquivo = this.$refs.file.files[0];
    },
    montarFormData() {
      let formData = new FormData();
      formData.append("planilha", this.arquivo);
      this.enviarPlanilhaPremiacao(formData);
    },
    prevPage() {
      this.pageNumber = this.pageNumber - 1;
    },
    nextPage() {
      this.pageNumber = this.pageNumber + 1;
    },
    nudaPagina(id) {
      this.pageNumber = id;
    },
    novoEnvio() {
      this.$store.commit("premiacao/SET_TIPO_FORMULARIO", "Novo");
      this.$store.commit("premiacao/SET_PREMIACAO_TELA", "Formulario");
    },
    verificaComponente() {
      return listarPremiacaoInstantanea(this.pageNumber, 10, this.filtros);
    },
    editarItem(id) {
      this.$store.commit("premiacao/SET_TIPO_FORMULARIO", "Editar");
      this.$store.commit("premiacao/SET_PREMIACAO_TELA", "Formulario");
      this.$store.commit("premiacao/SET_ID_FORMULARIO_EDITAR", id);
    },
    verItem(id) {
      this.$store.commit("premiacao/SET_TIPO_FORMULARIO", "Detalhes");
      this.$store.commit("premiacao/SET_PREMIACAO_TELA", "Detalhes");
      this.$store.commit("premiacao/SET_ID_FORMULARIO_EDITAR", id);
    },
    fecharSelect(item) {
      if (!this.form[item] || this.form[item].length < 1) {
        this.form[item] = 0;
      }
      this.pageNumber = 1;
    },
    filtrar() {
      this.filtros = this.form;
      this.verificaComponente();
    },
    formataCPF(cpf) {
      cpf = cpf.replace(/[^\d]/g, "");
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },
    limparFiltros() {
      this.form = {
        nome: null,
        cpf: null,
        email: null,
        dataCadastroInicial: null,
        dataCadastroFinal: null,
        statusPremiacao: 0
      };
      this.filtros = {};
      this.resetCalendario();
      this.verificaComponente();
    },
    atualizaCalendario(data) {
      this.form.dataCadastroInicial = stringToDatetimeZone(data.start);
      this.form.dataCadastroFinal = stringToDatetimeZone(data.end);
    },
    resetCalendario() {
      this.form.dataCadastroInicial = null;
      this.form.dataCadastroFinal = null;
    },
    getData() {
      return this.gerarRelatorio().then(resp => {
        return this.montarDownload(resp.data);
      });
    },
    moderarPremio(idUsuario, idHistorico, dataPremio){
      return historicoPremiacaoInstantanea(idUsuario, idHistorico, dataPremio)
    }
  },
  filters: {
    filterData,
    formataCPF
  },
  mounted() {
    this.pageNumber = 1;
    this.verificaComponente();
    this.montarDownload();
    this.verificaPlanilha();
  },
  watch: {
    camposInput() {
      this.verificaComponente();
    },
    dadosTabela() {
      this.semDados = this.dadosTabela.length <= 0;
    },
    pageNumber() {
      this.verificaComponente();
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.uploud {
  margin-bottom: 40px;
  .alinha-uploud {
    display: flex;
    width: 400px;
    margin-top: 0;
  }
}
h2 {
  color: $cinza-interno;
  font-size: 30px;
}
.btn-moderar {
  max-width: 200px;
  text-transform: none !important;
  color: #fff;
  background: #003169;
  font-size: 18px;
  height: 35px;
  padding: 0 15px;
}
</style>
