<template>
  <div class="default-moderar moderar-usuario">
    <div class="moderar-usuario-container">
      <h1>{{ camposInput.titulo }} <small>Detalhes</small></h1>
      <div class="moderar-body">
        <div class="moderar-row">
          <div class="moderar-panel">
            <h3>Informações do usuário:</h3>
            <div class="moderar-row">
              <div class="moderar-item">
                <label>Nome:</label><br />
                <p>{{ informacoesTabelaAtivada.usuario.nome }}</p>
              </div>
              <div class="moderar-item">
                <label>E-mail:</label><br />
                <p>{{ informacoesTabelaAtivada.usuario.email }}</p>
              </div>
              <div class="moderar-item">
                <label>Celular:</label><br />
                <p v-if="informacoesTabelaAtivada.celular != ''">{{ informacoesTabelaAtivada.celular }}</p>
                <p v-else>Sem celular</p>
              </div>
              <div class="moderar-item">
                <label>CPF:</label><br />
                <p>{{ informacoesTabelaAtivada.usuario.cpf | formataCPF }}</p>
              </div>
              <div class="moderar-item">
                <label>Gênero:</label><br />
                <p v-if="informacoesTabelaAtivada.genero != ''">{{ informacoesTabelaAtivada.genero }}</p>
                <p v-else>Sem Dados</p>
              </div>
              <div class="moderar-item">
                <label>Ativo:</label><br />
                <p>{{ informacoesTabelaAtivada.usuario.ativo ? "Sim" : "Não" }}</p>
              </div>
            </div>
            <div class="moderar-row">
              <div class="moderar-item">
                <label>Data Atualização:</label><br />
                <p>{{ informacoesTabelaAtivada.usuario.dataAtualizacao | filterDataHora }}</p>
              </div>
              <div class="moderar-item">
                <label>Data Cadastro:</label><br />
                <p>{{ informacoesTabelaAtivada.usuario.dataCadastro | filterDataHora }}</p>
              </div>
              <div class="moderar-item">
                <label>Data Expiração Esqueci Senha:</label><br />
                <p>{{ informacoesTabelaAtivada.usuario.dataExpiracaoEsqueciSenha | filterDataHora }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="moderar-row">
          <div class="moderar-panel">
            <h3>Endereço:</h3>
            <div class="moderar-row">
              <div class="moderar-item">
                <label>Endereço:</label><br />
                <p>{{ informacoesTabelaAtivada.endereco }}</p>
              </div>
              <div class="moderar-item">
                <label>nº:</label><br />
                <p>{{ informacoesTabelaAtivada.numero }}</p>
              </div>
              <div class="moderar-item">
                <label>Complemento:</label><br />
                <p>{{ informacoesTabelaAtivada.complemento }}</p>
              </div>
              <div class="moderar-item">
                <label>CEP:</label><br />
                <p>{{ informacoesTabelaAtivada.cep | formataCEP }}</p>
              </div>
              <div class="moderar-item">
                <label>Bairro:</label><br />
                <p>{{ informacoesTabelaAtivada.bairro }}</p>
              </div>
              <div class="moderar-item">
                <label>Cidade:</label><br />
                <p>{{ informacoesTabelaAtivada.cidade }}</p>
              </div>
              <div class="moderar-item">
                <label>UF:</label><br />
                <p>{{ informacoesTabelaAtivada.uf }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="moderar-row">
          <div class="moderar-panel">
            <h3>Aceites:</h3>
            <div class="moderar-row">
              <div class="moderar-item">
                <label>Aceite e-mail</label><br />
                <p>{{ informacoesTabelaAtivada.aceiteEmail ? `Sim` : `Não` }}</p>
              </div>
              <div class="moderar-item">
                <label>Aceite LGPD</label><br />
                <p v-if="informacoesTabelaAtivada.aceiteLGPD">
                  Aceito em {{ informacoesTabelaAtivada.dataAceiteLGPD | filterDataHora }}
                </p>
                <p v-else>Não</p>
              </div>
              <div class="moderar-item">
                <label>Aceite Regulamento</label><br />
                <p v-if="informacoesTabelaAtivada.aceiteRegulamento">
                  Aceito em {{ informacoesTabelaAtivada.dataAceiteRegulamento | filterDataHora }}
                </p>
                <p v-else>Não</p>
              </div>
              <div class="moderar-item">
                <label>Aceite SMS</label><br />
                <p>{{ informacoesTabelaAtivada.aceiteSMS ? `Sim` : `Não` }}</p>
              </div>
              <div class="moderar-item">
                <label>Aceite WhatsApp</label><br />
                <p>{{ informacoesTabelaAtivada.aceiteWhatsApp ? `Sim` : `Não` }}</p>
              </div>
              <div class="moderar-item">
                <label>Aceite WhatsApp</label><br />
                <p>{{ informacoesTabelaAtivada.aceiteWhatsApp ? `Sim` : `Não` }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btns-formulario">
      <button class="btn btn-cancelar" @click="cancelarFormuario">Voltar</button>
    </div>
  </div>
</template>

<script>
import { _imagemCupom } from "@/api/_caminhosApi";
import { numberToReal } from "@/utils/money";
import { filterData, filterDataHora } from "@/utils/date";
import { formataCPF, formataCEP } from "@/utils/masks";
export default {
  computed: {
    camposInput() {
      return this.$store.state.usuario.componenteAtivado;
    },
    formularioTipo() {
      return this.$store.state.usuario.formularioTipo;
    },
    tabela() {
      return this.$store.state.usuario.tabela;
    },
    idTabelaAtivada() {
      return this.$store.state.usuario.formularioEditarId;
    },
    informacoesTabelaAtivada() {
      return this.tabela.filter(item => item.id == this.idTabelaAtivada)[0];
    }
  },
  filters: {
    filterData,
    filterDataHora,
    formataCPF,
    formataCEP
  },
  methods: {
    _imagemCupom,
    numberToReal,
    cancelarFormuario() {
      this.$store.commit("premiacao/SET_PREMIACAO_TELA", "Lista");
    }
  }
};
</script>

<style lang="scss" scoped>
.moderar-usuario {
  .btns-formulario {
    justify-content: space-evenly;
  }
  .btn-enviar {
    &.btn-reprovar {
      background-color: red;
    }
    &.btn-aprovar {
      background-color: green;
      max-width: 250px;
    }
  }
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
