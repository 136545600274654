<template>
  <div>
    <transition name="fade-left" mode="out-in">
      <div class="premio" v-if="formOuLista === 'Lista'" key="Lista">
        <Tabela />
      </div>
      <div v-if="formOuLista === 'Detalhes'" class="premio-formulario" key="Detalhes">
        <TelaVer />
      </div>
    </transition>
  </div>
</template>

<script>
import Tabela from "@/components/premiacao/Tabela.vue";
import TelaVer from "@/components/premiacao/TelaVer.vue";
export default {
  components: {
    Tabela,
    TelaVer
  },
  data() {
    return {
      tela: "",
      telas: [
        {
          id: 1,
          ativado: true,
          titulo: "Ganhadores",
          componente: "Premiação Instantânea"
        }
      ]
    };
  },
  computed: {
    formOuLista() {
      return this.$store.state.premiacao.telaAtivada;
    },
    componentesMenu() {
      return this.telas.filter(item => item.ativado);
    }
  },
  created() {
    this.$store.commit("premiacao/SET_LINKATIVADO", this.componentesMenu[0].componente);
    this.$store.commit("premiacao/SET_COMPONENTE", this.componentesMenu[0]);
    this.$store.commit("premiacao/SET_PREMIACAO_TELA", "Lista");
  },
  watch: {
    formOuLista() {
      if (this.$store.state.interacoes.modal.modalAtivado) {
        this.$store.commit("interacoes/SET_ALERTA", {
          ativado: false,
          mensagem: "",
          tipo: ""
        });
      }
    },
    componentesMenu() {
      if (this.$store.state.interacoes.modal.modalAtivado) {
        this.$store.commit("interacoes/SET_ALERTA", {
          ativado: false,
          mensagem: "",
          tipo: ""
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.premio {
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  .premio-tabela {
    width: 100% !important;
  }
}
.premio-formulario {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 25px;

  @media (max-width: 750px) {
    padding: 25px 10px;
  }
}
</style>
